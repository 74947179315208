.agenda-title{
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    letter-spacing: 0.02em;
    text-justify: center;
    text-align: center;
}

.agenda-subtitle{
    color: #fff;
    font-size: 16px;
    display: block;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 0.02em;
    margin-top: 30px;
    text-justify: center;
    text-align: center;
}


.agenda-form{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.agenda-forms{
    width: 800px;
    height: 530px;
    border-radius: 1%;
    background-color: #CADED9;
}

.revisar-titulo{
    margin-top: 10px;
    color: #A8094A;
    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 800;
}

.revisar-contacto, .revisar-cita{
    margin-top: 10px;
    color: #A8094A;
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
}

.editar{
    color: black;
    margin-left: 10px;
    cursor: pointer;
}

.editar:hover{
    color: #A8094A;
}

.contacto-item, .cita-item{
    color: #C10E19;
    font-size: 20px;
    font-weight: 700;
}

.contacto-item span{
    color: black;
    font-size: 20px;
    font-weight: 600;
}

.cita-span{
    color: black;
    font-size: 20px;
    font-weight: 600;
}

@media (max-width: 768px){
    .agenda-forms{
      width: 300px;
    }
    .revisar-titulo{
        margin-top: 5px;
        font-size: 26px;
        font-weight: 800;
    }
    .revisar-contacto, .revisar-cita{
        margin-top: 5px;
        font-size: 18px;
    }
}
