.body {
  display: flex;
  background-color: #fff;
  max-width: 800px;
  height: 580px;
  border-radius: 5px;
}
.button {
  margin-right: 2.5px;
  margin-left: 2.5px;
  width: 70px;
  cursor: pointer;
  box-shadow: 0px 0px 2px gray;
  border: none;
  outline: none;
  font-size: 15px;
  padding: 5px;
  border-radius: 5px;
  color: white;
}

.header {
  padding: 10px;
  color: red;
  text-transform: uppercase;
  font-size: 26px;
  font-family: sans-serif;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
}
.header .button {
  background-color:#003E51;
}
#container {
  width: 500px;
}
#weekdays {
  width: 100%;
  display: flex;
  color: black;
  font-weight: bold;
}
#weekdays div {
  font-size: 14px;
  width: 70px;
  padding: 10px;
  text-align: center;
}
#calendar {
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
.day {
  width: 60px;
  padding: 10px;
  height: 60px;
  cursor: pointer;
  box-sizing: border-box;
  background-color: white;
  color: #000000;
  font-weight: bold;
  margin: 5px;
  box-shadow: 0px 0px 3px #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.day + .currentDay {
  background-color:#e8f4fa;
}


.green:hover{
  background: green;
}

.event {
  font-size: 10px;
  padding: 3px;
  background-color: #58bae4;
  color: white;
  border-radius: 5px;
  max-height: 55px;
  overflow: hidden;
}
.padding {
  cursor: default !important;
  background-color: #FFFCFF !important;
  box-shadow: none !important;
}
#newEventModal, #deleteEventModal {
  z-index: 20;
  padding: 25px;
  background-color: #e8f4fa;
  border-radius: 5px;
  width: 350px;
  top: 100px;
  left: calc(50% - 175px);
  position: absolute;
  font-family: sans-serif;
  margin: 40px;
}
#eventTitleInput {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 25px;
  border-radius: 3px;
  outline: none;
  border: none;
  box-shadow: 0px 0px 3px gray;
}
#eventTitleInput.error {
  border: 2px solid red;
}
#cancelButton, #deleteButton {
  background-color: red;
}
#saveButton, #closeButton {
  background-color: red;
}
#eventText {
  font-size: 14px;
}
#modalBackDrop {
  top: 0px;
  left: 0px;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.select {
  background-color: #eee;
  border-radius: 20px;
  margin-top : 5px;
  margin-bottom: 5px;
}


.red{
  background-color: red;
}

.disable{
  opacity: 0.3;
}

.not-valid{
  box-shadow: 0px 0px 3px red;
}

.error{
  color: #EF0B38;
}

.opacity{
  opacity: 0;
}

.direction{
  width: 300px;
}

.informacion-cita{
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
  color: red;
  font-weight: bold;
}

.reglas{
  color: red;

}

.reglas-dias{
  text-align: center;
  font-size: 16px;
  margin-top: 40px;
  color: black;
  font-weight: bold;
}

.reglas-texto{
  text-align: justify;
  font-size: 12px;
  color: black;
  font-weight: bold;
  margin: 10px 40px;
}

.fecha-cita{
  color: black;
  font-weight: bold;
  font-size: 14px;
}


@media(max-width: 768px){
  .body{
    max-width: 300px;
    flex-direction: column;
  }
  #weekdays{
    width: 200px;
  }

  #weekdays div{
    font-size: 9px;
    padding: 5px;
  }

  .day{
    width: 33px;
    padding: 2px;
    height: 33px;
  }
  #calendar{
    width: 65%;
    margin-right: 200px;
  }

  .button{
    width: 50px;
    font-size: 10px;
  }

  .header{
    width: 310px;
    height: 40px;
    font-size: 16px;
  }

  .informacion-cita{
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 0px;
  }

  .reglas-dias{
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 0px;
  }

  .reglas-texto{
    text-align: justify;
    font-size: 8px;
    margin: 2px 40px;
  }

  .fecha-cita{
    font-size: 12px;
    margin-bottom : 0px;
  }

  .select {
    border-radius: 20px;
    margin-top : 0px;
    margin-bottom: 4px;
  }

}
